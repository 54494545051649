import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Subcategory } from '../Subcategory';
import {
  getColour,
  getResourceLink,
  getSvgIconColorHeaderByCategory,
} from '../../../../common/utils';
import { Category } from '../../../../models/category.model';
import { TemplatesTable } from '../../../category/components/TemplatesTable';
import { Template } from '../../../../models/template.model';
import SVG from 'react-inlinesvg';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

export type CategorySectionProps = {
  id: number;
  name: string;
  icon: string;
  backgroundColour: string;
  textColour: string;
  borderColour?: string;
  subcategories?: Category[];
  isGroup?: boolean;
  templates: Template[];
};

export const CategorySection = ({
  name: categoryName,
  icon,
  subcategories = [],
  backgroundColour,
  textColour,
  isGroup,
  templates = [],
}: CategorySectionProps) => {
  const [loadImageError, setLoadImageError] = useState(false);
  return (
    <Grid container flexDirection='column'>
      {!isGroup && (
        <Grid
          container
          item
          alignItems={'center'}
          className='categories-section-header'
          sx={{
            backgroundColor: getColour(backgroundColour),
            border: !backgroundColour ? '1px solid #C7C6C6' : undefined,
          }}
        >
          {loadImageError ? (
            <FolderOutlinedIcon
              style={{ fill: '#000' }}
              className='categories-section-header-icon default-icon'
            />
          ) : (
            <SVG
              className='categories-section-header-icon'
              description={`${name} icon`}
              color={getSvgIconColorHeaderByCategory(categoryName.toLowerCase())}
              preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
              src={getResourceLink(icon)}
              onError={() => setLoadImageError(true)}
              loader={
                <FolderOutlinedIcon
                  style={{ fill: '#000' }}
                  className='categories-section-header-icon default-icon'
                />
              }
            />
          )}
          <Typography
            component={'h2'}
            sx={{ color: getColour(textColour) }}
            className='categories-section-header-title'
          >
            {categoryName}
          </Typography>
        </Grid>
      )}
      {templates.length > 0 && (
        <Grid container item justifyContent='center' className='templates-table'>
          <TemplatesTable templates={templates} />
        </Grid>
      )}
      <Grid
        container
        sx={{ mb: 4, mt: 1 }}
        wrap='wrap'
        spacing={4}
        className={`subcategory-${categoryName.toLowerCase()}-container`}
      >
        {subcategories.map(({ id, name, icon, label, show_order }) => (
          <Subcategory
            key={id}
            id={id}
            name={name}
            label={label}
            showOrder={show_order}
            icon={icon}
            parentName={categoryName}
          />
        ))}
      </Grid>
    </Grid>
  );
};
