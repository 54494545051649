import React from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import TemplateDashboardSearchBar from '../templates/TemplateDashboardSearchBar';
import { useCategory } from './hooks/useCategory';
import { TemplatesTable } from './components/TemplatesTable';
import CategoriesPage from '../categories/CategoriesPage';
import SVG from 'react-inlinesvg';
import { getResourceLink, getSvgIconColorByCategory } from '../../common/utils';

export const CategoryPage = () => {
  const { searchCategories, category /*, name*/ } = useCategory();

  /*if (['employment', 'business'].includes(name)) {
    return <CategoriesPage />;
  }*/

  if (category && category.sub_categories.length > 0) {
    return <CategoriesPage />;
  }

  if (!category) {
    return (
      <Grid container justifyContent='center'>
        {' '}
        <CircularProgress />{' '}
      </Grid>
    );
  }

  return (
    <Grid container flexDirection='column' spacing={4} alignItems='center'>
      <Grid
        wrap={'nowrap'}
        container
        xs={12}
        sm={8}
        item
        justifyContent='center'
        alignItems='center'
      >
        <Grid item>
          <SVG
            color={getSvgIconColorByCategory(category.parentName)}
            preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
            description='category icon'
            className='category-icon'
            src={getResourceLink(category.icon)}
          />
        </Grid>
        <Grid item>
          <Typography className='category-name text-center' component={'h1'} gutterBottom={false}>
            {category.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item justifyContent='center'>
        <TemplateDashboardSearchBar
          filtered
          categories={searchCategories}
          placeholder={'Enter a template name here, choose it from the list below if found'}
        />
      </Grid>
      <Grid container item justifyContent='center'>
        <TemplatesTable templates={category.templates} />
      </Grid>
    </Grid>
  );
};
