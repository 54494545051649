import { configureStore } from '@reduxjs/toolkit';
import companiesReducer from './slices/companies/companiesSlice';
import categoriesReducer from './slices/categories/categoriesSlice';
import userReducer from './slices/user/userSlice';
import documentsReducer from './slices/documents/documentsSlice';
import userSigningInfoReducer from './slices/user-signing-info/userSigningInfoSlice';

const store = configureStore({
  reducer: {
    companies: companiesReducer,
    categories: categoriesReducer,
    user: userReducer,
    documents: documentsReducer,
    userSigningInfo: userSigningInfoReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
