import React, { ChangeEvent, useState, useEffect } from 'react';
import DialogItem from '../../../../../../common/dialog-item/DialogItem';
import { Divider, Grid } from '@mui/material';
import EmploymentSettingsIcon from '../../../../../../../static/assets/svg/menu/profile-icon.svg';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from '../../../../../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../../../../../slices/companies/companiesSlice';
import { CompanyMember } from '../../../../../../models/member.model';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { WHITE_COLOR } from '../../../../../../common/constants/colors';

type Data = { template_id: number; insert_pairs: { [key: string]: string | number } };
const ContractGeneratorDialog = (props: {
  open: boolean;
  handleClose: () => void;
  templateTitle: string;
  templateId: number;
  handleGenerateTemplate: (data: Data) => void;
  handleDownloadTemplateInstance: (id: number) => void;
  templateInstanceId: null | number;
}) => {
  const {
    open,
    handleClose,
    templateTitle,
    templateId,
    handleGenerateTemplate,
    handleDownloadTemplateInstance,
    templateInstanceId,
  } = props;
  const { t } = useTranslation();
  const company = useAppSelector(selectAllCompanies)[0];
  const employees: CompanyMember[] = company?.members || [];
  const employeesFiltered = employees.filter((el) =>
    el.groups.every((item) => item.name !== 'Company admin')
  );
  const employeesSorted = employeesFiltered.sort((a, b) =>
    a.first_name.localeCompare(b.first_name)
  );
  const [step, setStep] = useState<number>(1);
  const [companyDataEmployer, setCompanyDataEmployer] = useState<string>('');
  const [employeeData, setEmployeeData] = useState<string>('');
  const [documentFormat, setDocumentFormat] = useState<string>('word');
  const [saveDocumentToVault, setSaveDocumentToVault] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const resetDataOnClose = () => {
    setCompanyDataEmployer('');
    setEmployeeData('');
    setDocumentFormat('');
    setStartDate(null);
    setEndDate(null);
    setStep(1);
  };

  const handleSubmitWizard = () => {
    const data: Data = {
      template_id: templateId,
      insert_pairs: {
        '[Insert company name]': company ? company.id : null,
      },
    };
    if (employeeData) {
      data.insert_pairs['[Insert employee full name]'] = parseInt(employeeData);
    }
    handleGenerateTemplate(data);
  };

  const Stepper = (props: { activeStep: number }) => {
    return (
      <div className='stepper d-flex justify-content-between'>
        <div className={props.activeStep === 1 ? 'active step' : 'completed step'}>
          {props.activeStep !== 1 && <CheckRoundedIcon style={{ fill: WHITE_COLOR }} />}
        </div>
        <div className='divider'></div>
        <div
          className={
            props.activeStep === 2 ? 'active step' : step === 3 ? 'completed step' : 'step'
          }
        >
          {props.activeStep === 3 && <CheckRoundedIcon style={{ fill: WHITE_COLOR }} />}
        </div>
        <div className='divider'></div>
        <div className={props.activeStep === 3 ? 'active step' : 'step'}></div>
      </div>
    );
  };

  useEffect(() => {
    if (templateInstanceId) {
      setTimeout(() => {
        setStep(3);
      });
    }
  }, [templateInstanceId]);

  return (
    <>
      <DialogItem
        open={open}
        handleClose={() => {
          resetDataOnClose();
          handleClose();
        }}
        closeIcon={true}
      >
        <Grid>
          <Grid container item className='setting-panel-header ps-0 pe-0'>
            <h4>{t('contractGeneratorDialog.generatorTitle')}</h4>
            <Tooltip title={t('messages.contractGeneratorGeneralMessage')} placement='top'>
              <InfoIcon fontSize='small' color='secondary' />
            </Tooltip>
          </Grid>
          <Divider />
          <Grid
            container
            item
            className='generate-contract-header setting-panel-header flex-nowrap ps-0 pe-0'
            alignItems='flex-start'
          >
            <EmploymentSettingsIcon />
            <h4 className='fw-normal'>{templateTitle}</h4>
          </Grid>
          {step === 1 && (
            <div className='setting-panel-body ps-0 pe-0'>
              <ValidatorForm onSubmit={() => setStep(2)}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextValidator
                      label={t('labels.companyDataEmployer')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setCompanyDataEmployer(e.target.value)
                      }
                      disabled={true}
                      name='companyDataEmployer'
                      value={company.name}
                      tabIndex={-1}
                    />
                  </Grid>
                  <Grid className='position-relative' item xs={12} md={6}>
                    <Tooltip
                      title={t('messages.selectAUser')}
                      placement='top'
                      style={{ position: 'absolute', right: '0', top: '32px', zIndex: 3 }}
                    >
                      <InfoIcon fontSize='small' color='secondary' />
                    </Tooltip>
                    <TextValidator
                      select
                      label={t('labels.chooseEmployee')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setEmployeeData(e.target.value);
                      }}
                      name='employee'
                      value={employeeData}
                    >
                      <MenuItem key={'noEmployee'} value={''}>
                        {t('contractGeneratorDialog.noEmployee')}
                      </MenuItem>
                      {employeesSorted.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.first_name + ' ' + option.last_name}
                        </MenuItem>
                      ))}
                    </TextValidator>
                  </Grid>
                  <Grid item xs={12} md={6} className='datepicker-container'>
                    <InputLabel id='startDateLabel'>{t('labels.startDate')}</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          aria-labelledby='startDateLabel'
                          value={startDate}
                          format='DD.MM.YYYY'
                          onChange={(newValue) => setStartDate(newValue)}
                          slotProps={{
                            field: { clearable: true },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} className='datepicker-container'>
                    <InputLabel id='endDateLabel'>{t('labels.endDate')}</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          format='DD.MM.YYYY'
                          aria-labelledby='endDateLabel'
                          value={endDate}
                          onChange={(newValue) => setEndDate(newValue)}
                          slotProps={{
                            field: { clearable: true },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Stepper activeStep={1} />
                <Grid container className='buttons-row mt-4' justifyContent='flex-end'>
                  <Button
                    type='submit'
                    role='button'
                    disabled={false}
                    variant='contained'
                    size='large'
                  >
                    {t('buttons.next')}
                  </Button>
                </Grid>
              </ValidatorForm>
            </div>
          )}
          {step === 2 && (
            <div className='setting-panel-body ps-0 pe-0'>
              <ValidatorForm onSubmit={() => handleSubmitWizard()}>
                <Grid container className='form-controls' spacing={4}>
                  <Grid className='position-relative' item xs={12} md={6}>
                    <Tooltip
                      title={t('messages.contractGeneratorFinalStep')}
                      placement='top'
                      style={{ position: 'absolute', right: '0', top: '32px', zIndex: 3 }}
                    >
                      <InfoIcon fontSize='small' color='secondary' />
                    </Tooltip>
                    <TextValidator
                      select
                      disabled
                      label={t('labels.selectOutputFormat')}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDocumentFormat(e.target.value)
                      }
                      name='documentFormat'
                      value={'word'}
                    >
                      {/* <MenuItem key={'pdf'} value={'pdf'}>
                  {t('contractGeneratorDialog.formatPDF')}
                </MenuItem>*/}
                      <MenuItem selected={true} key={'word'} value={'word'}>
                        {t('contractGeneratorDialog.formatWord')}
                      </MenuItem>
                    </TextValidator>
                  </Grid>
                  {/*<Grid item xs={12} md={6} className="d-flex align-items-end">
                        <FormControlLabel
                            control={<Switch
                                checked={saveDocumentToVault}
                                onChange={(event) => {
                                  setSaveDocumentToVault(event.target.checked);
                                }}
                            />}
                            label={t('labels.saveDocumentToVault')}/>
                      </Grid>*/}
                </Grid>
                <Stepper activeStep={2} />
                <Grid container className='buttons-row mt-4' justifyContent='space-between'>
                  <Button
                    type='button'
                    role='button'
                    disabled={false}
                    variant='outlined'
                    size='large'
                    onClick={() => setStep(1)}
                  >
                    {t('buttons.goBack')}
                  </Button>
                  <Button
                    type='submit'
                    role='button'
                    disabled={false}
                    variant='contained'
                    size='large'
                  >
                    {t('buttons.create')}
                  </Button>
                </Grid>
              </ValidatorForm>
            </div>
          )}
          {step === 3 && (
            <div className='setting-panel-body ps-0 pe-0'>
              <div className='d-flex justify-content-center'>
                <Button
                  variant='contained'
                  size='medium'
                  onClick={() => handleDownloadTemplateInstance(templateInstanceId)}
                >
                  {t('buttons.downloadContract')}
                </Button>
              </div>
              <Stepper activeStep={3} />
              <Grid container className='buttons-row mt-4' justifyContent='space-between'>
                <Button
                  type='button'
                  role='button'
                  disabled={false}
                  variant='outlined'
                  size='large'
                  onClick={() => setStep(2)}
                >
                  {t('buttons.goBack')}
                </Button>
              </Grid>
            </div>
          )}
        </Grid>
      </DialogItem>
    </>
  );
};

export default ContractGeneratorDialog;
