import React, { useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import { NWClient } from '../../../../../../client/NWClient';
import { StyledTableCell } from '../TableCell';
import { useTranslation } from 'react-i18next';
import DialogItem from '../../../../../../common/dialog-item/DialogItem';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { ROUTES } from '../../../../../../common/constants/routes';
import { Helpers } from '../../../../../../common/helpers/helpers';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TemplateInstanceModel } from '../../../../../../models/template-instance.model';
import ContractGeneratorDialog from '../ContractGeneratorDialog';
import { ProgressOverlay } from '../../../../../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../../../../common/hooks/useAppSelector';
import { selectAllCompanies } from '../../../../../../slices/companies/companiesSlice';

export type ActionsCellProps = {
  row: {
    id: number;
    name: string;
  };
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit';
};

export const ActionsCell = ({ row, align = 'left' }: ActionsCellProps) => {
  const token = localStorage.getItem('access_token');
  const company = useAppSelector(selectAllCompanies)[0];
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [errorText, setErrorText] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [bookalopeLoading, setBookalopeLoading] = useState(false);
  const [templateInstanceLoading, setTemplateInstanceLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [templateInstanceId, setTemplateInstanceId] = useState<null | number>(null);
  const [generatorOpened, setGeneratorOpened] = useState<boolean>(false);
  const [pendingStatus, setPendingStatus] = useState<boolean>(false);

  const errorMessageTitle = t('messages.errorOccurred');
  const NoPermissionMessage = () => {
    return (
      <>
        {t('messages.startSubscription')}&nbsp;<Link to={ROUTES.PRICING}>{t('links.here')}</Link>.
      </>
    );
  };
  const noPermissionTitle = t('messages.downloadMessageTitle');

  const setError = (errorMessage: string | null) => {
    setErrorState(true);
    setErrorText(errorMessage);
  };

  const handleDownloadLink = (id: number) => {
    setDownloadLoading(true);
    NWClient.downloadTemplate(token, id, row.name)
      .catch((error) => {
        const status = error.response && error.response.status;
        setErrorStatus(status);
        const errorMessage = Helpers.returnErrorText(error);
        status === 405
          ? navigate(`${ROUTES.COMPANY_CREATE}?backUrl=${location.pathname}`)
          : status !== 403
          ? setError(errorMessage.toString())
          : setError(null);
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const handleGenerateTemplate = (data: {
    template_id: number;
    insert_pairs: { [key: string]: string | number };
  }) => {
    setPendingStatus(true);
    setTemplateInstanceId(null);
    NWClient.post(token, 'template-instance/generate_instance', data)
      .then((res: TemplateInstanceModel) => {
        setTimeout(() => {
          setTemplateInstanceId(res.id);
          setBookalopeLoading(false);
          setPendingStatus(false);
          toast.success(t('messages.templateGenerated'), { theme: 'colored' });
        }, 10000);
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        setErrorStatus(status);
        const errorMessage = Helpers.returnErrorText(error);
        setErrorState(true);
        status !== 403 ? setErrorText(errorMessage.toString()) : setErrorText(null);
        setBookalopeLoading(false);
        setPendingStatus(false);
      });
  };

  const handleDownloadTemplateInstance = (id: number) => {
    setTemplateInstanceLoading(true);
    setPendingStatus(true);
    NWClient.downloadTemplateInstance(token, id, row.name)
      .then(() => {
        toast.success(t('messages.downloadingStarted'), { theme: 'colored' });
      })
      .catch((error) => {
        const status = error.response && error.response.status;
        setErrorStatus(status);
        const errorMessage = Helpers.returnErrorText(error);
        setErrorState(true);
        status !== 403 ? setErrorText(errorMessage.toString()) : setErrorText(null);
      })
      .finally(() => {
        setTemplateInstanceLoading(false);
        setPendingStatus(false);
      });
  };

  const MenuBlock = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div className='d-inline-flex'>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem>
            <Button
              onClick={() => {
                handleDownloadTemplateInstance(templateInstanceId);
              }}
              className='templates-table-action'
              aria-label={`bookalope ${row.name} button`}
              disabled={templateInstanceLoading || !templateInstanceId}
            >
              {!templateInstanceLoading ? (
                t('buttons.bookalopeDownload')
              ) : (
                <CircularProgress size={20} />
              )}
            </Button>
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <>
      <StyledTableCell align={align}>
        <Grid container justifyContent='flex-end'>
          <Grid item xs={12} className='d-flex align-items-center justify-content-end'>
            <Button
              onClick={() => {
                handleDownloadLink(row.id);
              }}
              className='templates-table-action'
              aria-label={`download ${row.name} button`}
              disabled={downloadLoading}
            >
              {!downloadLoading ? t('buttons.download') : <CircularProgress size={20} />}
            </Button>
            {!process.env.ENV_TYPE || (process.env.ENV_TYPE !== 'PROD' && company) ? (
              <Button
                onClick={() => {
                  setGeneratorOpened(true);
                }}
                className='templates-table-action'
                aria-label={`create ${row.name} button`}
                disabled={downloadLoading}
              >
                {t('buttons.create')}
              </Button>
            ) : null}
            {!process.env.ENV_TYPE || process.env.ENV_TYPE !== 'PROD' ? <MenuBlock /> : null}
          </Grid>
        </Grid>
        <DialogItem
          isErrorMessage={errorState && errorStatus !== 403}
          open={errorState}
          title={errorStatus !== 403 ? errorMessageTitle : noPermissionTitle}
          text={errorText}
          handleClose={() => setErrorState(false)}
        >
          {errorStatus === 403 ? <NoPermissionMessage /> : null}
        </DialogItem>
        {company && (
          <ContractGeneratorDialog
            templateId={row.id}
            open={generatorOpened}
            handleClose={() => setGeneratorOpened(false)}
            templateTitle={row.name}
            handleGenerateTemplate={handleGenerateTemplate}
            handleDownloadTemplateInstance={handleDownloadTemplateInstance}
            templateInstanceId={templateInstanceId}
          />
        )}
        {pendingStatus ? <ProgressOverlay /> : ''}
      </StyledTableCell>
    </>
  );
};
