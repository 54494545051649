import React, { useCallback } from 'react';

export const useSideNavbar = (): { isOpen: boolean; toggle: () => void } => {
  const [isOpen, setIsOpen] = React.useState<boolean>(window.innerWidth > 991);

  const toggle = useCallback(() => {
    setIsOpen((status) => !status);
  }, []);

  return { isOpen, toggle };
};
