import { BLACK_COLOR, BRAND_COLOR, ORANGE_COLOR, PINK_COLOR, WHITE_COLOR } from './colors';

import { getResourceLink } from '../utils';

export const DOCUMENTS_CATEGORIES = [
  {
    key: 'Employment',
    name: 'Employment',
    icon: getResourceLink('/icons/Employment/Employment.svg'),
    text_colour: WHITE_COLOR,
    background_colour: PINK_COLOR,
    link: '/document-vault/employment',
  },
  {
    key: 'Business',
    name: 'Business',
    icon: getResourceLink('/icons/Business/Business.svg'),
    text_colour: BLACK_COLOR,
    background_colour: ORANGE_COLOR,
    link: '/document-vault/business',
  },
  {
    key: 'Customers',
    //name: 'buttons.viewAllCategories',
    name: 'Customers',
    icon: '/assets/svg/customers.svg',
    text_colour: WHITE_COLOR,
    background_colour: BRAND_COLOR,
    borderColour: BRAND_COLOR,
    link: '/document-vault/customers',
  },
  {
    key: 'Other',
    //name: 'buttons.viewAllCategories',
    name: 'Other',
    icon: getResourceLink('/icons/Category/Category.svg'),
    text_colour: BLACK_COLOR,
    background_colour: WHITE_COLOR,
    borderColour: BRAND_COLOR,
    link: '/document-vault/other',
  },
];
