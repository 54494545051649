import React, { useEffect, useState, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import NWAppBar from '../../common/nw-app-bar/NWAppBar';
import NWSideNav from '../../common/nw-side-nav/NWSideNav';
import { ROUTES } from '../../common/constants/routes';
import { NWClient } from '../../client/NWClient';
import { API_CONSTANTS } from '../../common/constants/api';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';

const Layout = (): JSX.Element => {
  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>();
  const [pendingStatus, setPendingStatus] = useState(true);
  const intervalDuration = API_CONSTANTS.CHECK_TOKEN_INTERVAL;
  const token = localStorage.getItem('access_token');
  const location = useLocation();
  const locationExceptions = [
    ROUTES.SIGN_UP,
    ROUTES.EMAIL_SENT,
    ROUTES.PROCESS_SUCCESS,
    ROUTES.CONFIRMATION,
    ROUTES.LOGIN,
    ROUTES.ACCEPTED_INVITE,
    ROUTES.PASSWORD_RESET,
  ];
  const locationIncludeExceptionUrl = locationExceptions.some((el) => {
    return location.pathname.includes(el);
  });
  const locationWithIframes = [ROUTES.SIGNING, ROUTES.DOCUMENTS];
  const locationWithIframesUrl = locationWithIframes.some((el) => {
    return location.pathname.includes(el);
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (locationIncludeExceptionUrl) {
      setPendingStatus(false);
    } else if (!locationIncludeExceptionUrl && token) {
      setPendingStatus(false);
    } else if (!locationIncludeExceptionUrl && !token) {
      navigate(ROUTES.LOGIN);
    }
  }, [locationIncludeExceptionUrl, token]);

  useEffect(() => {
    if (!locationIncludeExceptionUrl) {
      intervalRef.current = setInterval(() => {
        NWClient.currentUser(token).catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem('access_token');
            navigate(ROUTES.LOGIN);
          }
        });
      }, intervalDuration);
    }

    return () => clearInterval(intervalRef.current);
  }, [intervalDuration, locationIncludeExceptionUrl, navigate, token]);

  const renderedLayout = () => {
    if (locationIncludeExceptionUrl) {
      return <Outlet />;
    }

    return (
      <React.Fragment>
        {pendingStatus ? (
          <ProgressOverlay />
        ) : (
          <Box className='layout'>
            <NWSideNav />
            <Box sx={{ width: '100%' }}>
              <NWAppBar />
              <Container maxWidth={locationWithIframesUrl ? false : 'lg'}>
                <Outlet />
              </Container>
            </Box>
          </Box>
        )}
      </React.Fragment>
    );
  };

  return renderedLayout();
};
export default Layout;
