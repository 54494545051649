import React, { PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

interface DialogItemProps {
  open: boolean;
  title?: string;
  text?: string;
  isErrorMessage?: boolean;
  handleClose: () => void;
  noDefaultActionsRow?: boolean;
  closeIcon?: boolean;
}

const DialogItem = (props: PropsWithChildren<DialogItemProps>) => {
  const {
    open,
    title,
    text,
    isErrorMessage,
    children,
    handleClose,
    noDefaultActionsRow,
    closeIcon,
  } = props;

  const errorClass = isErrorMessage ? 'error-message' : 'operation-message';

  return (
    <Dialog open={open} onClose={handleClose} className={errorClass}>
      {closeIcon && (
        <IconButton
          onClick={handleClose}
          className='position-absolute'
          style={{
            right: 5,
            top: 5,
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {text ? <DialogContentText>{text}</DialogContentText> : null}
        {children}
      </DialogContent>
      {!noDefaultActionsRow && !closeIcon && (
        <DialogActions>
          <Button variant='contained' size='medium' onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogItem;
