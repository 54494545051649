import React, { useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import { NWClient } from '../../../client/NWClient';
import { StyledTableCell } from '../../category/components/TemplatesTable/components/TableCell';
import { useTranslation } from 'react-i18next';
import DialogItem from '../../../common/dialog-item/DialogItem';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants/routes';
import { fetchDocuments, deleteDocument } from '../../../slices/documents/documentsSlice';
import { useAppDispatch } from '../../../common/hooks/useAppDispatch';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ProgressOverlay } from '../../../common/progress-overlay/ProgressOverlay';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../common/hooks/useAppSelector';
import { selectUser } from '../../../slices/user/userSlice';
import { DocumentSigningModel } from '../../../models/document.signing.model';

export type ActionsCellProps = {
  row: {
    id: number;
    name: string;
  };
  align: 'left' | 'center' | 'right' | 'justify' | 'inherit';
};

export const ActionsCell = ({ row, align = 'left' }: ActionsCellProps) => {
  const token = localStorage.getItem('access_token');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUser);
  const [errorText, setErrorText] = useState('');
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [formatMessage, setFormatMessage] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(false);
  const [deleteDocumentMessageVisible, setDeleteDocumentMessageVisible] = useState(false);
  const [documentToDeleteId, setDocumentToDeleteId] = useState<null | number>(null);

  const errorMessageTitle = t('messages.errorOccurred');
  const NoPermissionMessage = () => {
    return (
      <>
        {t('messages.startSubscription')}&nbsp;<Link to={ROUTES.PRICING}>{t('links.here')}</Link>.
      </>
    );
  };
  const noPermissionTitle = t('messages.downloadMessageTitle');

  const handleDownloadLink = (id: number) => {
    setDownloadLoading(true);
    NWClient.downloadDocument(token, id, row.name)
      .catch(() => {
        toast.error(t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const handleDelete = (id: number) => {
    setDeleting(true);
    setDeleteDocumentMessageVisible(false);
    setPendingStatus(true);
    dispatch(deleteDocument({ token, id }))
      .unwrap()
      .catch((error) => {
        toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
      })
      .finally(() => {
        setDeleting(false);
        setDocumentToDeleteId(null);
        setPendingStatus(false);
      });
  };

  const handleSign = (document_upload: number, documentFormat: string) => {
    if (documentFormat.toLowerCase() === 'pdf') {
      const data = {
        user: currentUser.id,
        document_upload,
        //signing_doc_id: 1, //should be optional later, to refactor
      };
      setPendingStatus(true);
      NWClient.post(token, 'document-sign', data, true)
        .then((res: DocumentSigningModel) => {
          navigate(`${ROUTES.DOCUMENTS}/${res.signing_doc_id}`);
        })
        .finally(() => {
          setPendingStatus(false);
        });
    } else {
      setFormatMessage(true);
    }
  };

  const MenuBlock = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <div className='d-inline-flex'>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem style={{ padding: 0 }}>
            <Button
              style={{ width: '100%', borderRadius: 0 }}
              onClick={() => {
                setDocumentToDeleteId(row.id);
                setDeleteDocumentMessageVisible(true);
              }}
              className='templates-table-action'
              aria-label={`delete ${row.name} button`}
              disabled={downloadLoading || deleting}
            >
              {!deleting ? t('buttons.delete') : <CircularProgress size={20} />}
            </Button>
          </MenuItem>
        </Menu>
      </div>
    );
  };

  return (
    <>
      <StyledTableCell align={align}>
        <Grid container justifyContent='flex-end'>
          <Grid item xs={12} className='d-flex align-items-center justify-content-end'>
            <Button
              onClick={() => {
                handleDownloadLink(row.id);
              }}
              className='templates-table-action'
              aria-label={`download ${row.name} button`}
              disabled={downloadLoading || deleting}
            >
              {!downloadLoading ? t('buttons.download') : <CircularProgress size={20} />}
            </Button>
            <Button
              onClick={() => {
                handleSign(
                  Number(row.id),
                  row.name.substring(row.name.length - 3, row.name.length)
                );
              }}
              className='templates-table-action'
              aria-label={`sign ${row.name} button`}
              disabled={downloadLoading || deleting}
            >
              {t('buttons.sign')}
            </Button>
            <MenuBlock />
          </Grid>
        </Grid>
        <DialogItem
          isErrorMessage={false}
          open={formatMessage}
          title={null}
          text={t('messages.pdfMessage')}
          handleClose={() => setFormatMessage(false)}
        />
        <DialogItem
          isErrorMessage={errorState && errorStatus !== 403}
          open={errorState}
          title={errorStatus !== 403 ? errorMessageTitle : noPermissionTitle}
          text={errorText}
          handleClose={() => setErrorState(false)}
        >
          {errorStatus === 403 ? <NoPermissionMessage /> : null}
        </DialogItem>
        <DialogItem
          isErrorMessage={false}
          open={deleteDocumentMessageVisible}
          title={
            deleteDocumentMessageVisible
              ? t('dialogTitles.deleteFile')
              : t('dialogTitles.deleteDefault')
          }
          text={
            deleteDocumentMessageVisible ? t('messages.deleteFile') : t('messages.deleteDefault')
          }
          noDefaultActionsRow={true}
          handleClose={() => {
            setDeleteDocumentMessageVisible(false);
          }}
        >
          <div className='buttons-row d-flex flex-wrap justify-content-end mt-3'>
            <Button
              onClick={() => handleDelete(documentToDeleteId)}
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              type='button'
              role='button'
              variant='outlined'
              size='medium'
            >
              {t('buttons.deleteFile')}
            </Button>
            <Button
              type='button'
              role='button'
              variant='contained'
              size='medium'
              style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
              onClick={() => {
                setDeleteDocumentMessageVisible(false);
                setDocumentToDeleteId(null);
              }}
            >
              {t('buttons.goBack')}
            </Button>
          </div>
        </DialogItem>
        {pendingStatus ? <ProgressOverlay /> : ''}
      </StyledTableCell>
    </>
  );
};
