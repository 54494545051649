import React from 'react';
import { useTranslation } from 'react-i18next';
import { DOCUMENTS_CATEGORIES } from '../../common/constants/documents_categories';
import { SearchCategoryList } from '../templates/components/SearchCategories/SearchCategoryList';

const DocumentVault = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className='text-center mb-5'>Document vault</h1>
      <div className='d-flex flex-wrap justify-content-center align-items-start'>
        <div className='d-flex flex-wrap justify-content-center'>
          <p className='text-center mb-5 fs-2'>{t('messages.storeDocuments')}</p>
          <SearchCategoryList categories={DOCUMENTS_CATEGORIES} />
        </div>
      </div>
    </div>
  );
};

export default DocumentVault;
