import React, { useState } from 'react';
import { ROUTES } from '../../../../common/constants/routes';
import { useTranslation } from 'react-i18next';
import { DashboardLinkItem } from './DashboardLinkItem';
import DialogItem from '../../../../common/dialog-item/DialogItem';

const DashboardLinks = () => {
  const { t } = useTranslation();
  const [featureComingSoonMessage, setFeatureComingSoonMessage] = useState(false);
  return (
    <>
      <ul className='dashboard-links list-unstyled d-flex flex-wrap justify-content-center justify-content-lg-around'>
        {(process.env.ENV_TYPE === 'DEV' || window.location.host.includes('com.au')) && (
          <DashboardLinkItem
            route={ROUTES.CATEGORY_LIST}
            imageSrc='assets/svg/menu/business-dashboard-icon.svg'
            linkText={t('pages.templateCategories')}
          />
        )}
        <DashboardLinkItem
          route={ROUTES.DOCUMENT_VAULT}
          imageSrc='assets/svg/menu/vault-icon.svg'
          linkText={t('pages.documentVault')}
          svgClassName='vault-icon'
        />
        <DashboardLinkItem
          route={ROUTES.DOCUMENTS}
          imageSrc='assets/svg/menu/documents-signing.svg'
          linkText={t('pages.signing')}
          svgClassName='signing-icon'
        />
        <DashboardLinkItem
          route={ROUTES.EMPLOYEE_PROFILES}
          imageSrc='assets/svg/menu/employment-icon.svg'
          linkText={t('pages.employeeProfiles')}
        />
        <DashboardLinkItem
          route={ROUTES.SUPPLIER_PROFILES}
          imageSrc='assets/svg/menu/business-icon.svg'
          linkText={t('pages.supplierProfiles')}
          svgClassName='suppliers-icon'
        />
        <DashboardLinkItem
          onClick={() => setFeatureComingSoonMessage(true)}
          imageSrc='assets/svg/customers.svg'
          linkText={t('pages.customerProfiles')}
          svgClassName='customers-icon'
        />
      </ul>
      <DialogItem
        isErrorMessage={false}
        open={featureComingSoonMessage}
        title={null}
        text={t('messages.featureComingSoon')}
        handleClose={() => setFeatureComingSoonMessage(false)}
      />
    </>
  );
};

export { DashboardLinks };
