import React, { useState } from 'react';
import { NWClient } from '../../../../../../client/NWClient';
import { Employee } from '../../../../../../models/employee.model';

const initialEmployeesTableState = [
  { id: 1, email: '', full_name: '' },
  {
    id: 2,
    email: '',
    full_name: '',
  },
  { id: 3, email: '', full_name: '' },
  { id: 4, email: '', full_name: '' },
];

export const useEmployeesForm = () => {
  const token = localStorage.getItem('access_token');

  const [employees, setEmployees] = useState(initialEmployeesTableState);

  const handleAddEmployee = () => {
    setEmployees((prevState) => [
      ...prevState,
      { id: prevState.length + 1, email: '', full_name: '' },
    ]);
  };

  const handleSubmitForm = (e: React.MouseEvent<HTMLElement>, employee: Employee) => {
    return NWClient.post<Employee>(token, 'user/invite', employee);
  };

  const handleRemoveForm = (e: React.MouseEvent<HTMLElement>, id: number) => {
    setEmployees((prevState) => prevState.filter((employee) => employee.id !== id));
  };

  return {
    employees,
    handleAddEmployee,
    handleSubmitForm,
    handleRemoveForm,
  };
};
